<template>
  <div>

    <!-- <div class="container-sm text-center neon d-xl-none d-md-none"> -->
    <nav class="app__navbar bottombar-mobile">
      <ul class="navbar-list container">
        <li class="navbar-item">
          <router-link :to="{ name: 'home' }">
            <a
              aria-current="page"
              class="navbar-link"
              :class="{ 'nuxt-link-active nuxt-link-exact-active': $route.name === 'home' }"
            ><i class="icon fad fa-home-heart" /> <span>หน้าแรก</span></a>
          </router-link>
        </li>
        <li class="navbar-item">
          <router-link :to="{ name: 'list-select' }">
            <a
              class="navbar-link"
              :class="{ 'nuxt-link-active nuxt-link-exact-active': $route.name === 'list-select' }"
            ><i class="icon fad fa-credit-card-front" /> <span>โพยหวย</span></a>
          </router-link>
        </li>
        <li class="navbar-item">
          <router-link :to="{ name: 'lottery' }">
            <a
              class="navbar-link"
              :class="{ 'nuxt-link-active nuxt-link-exact-active': $route.name === 'lottery' }"
            ><i class="icon fad fa-bell-school" /> <span>แทงหวย</span></a>
          </router-link>
        </li>
        <li class="navbar-item">
          <router-link :to="{ name: 'reward' }">
            <a
              class="navbar-link"
              :class="{ 'nuxt-link-active nuxt-link-exact-active': $route.name === 'reward' }"
            ><i class="icon fad fa-trophy-alt" /> <span>ผลรางวัล</span></a>
          </router-link>
        </li>
        <li class="navbar-item">
          <router-link :to="{ name: 'profile' }">
            <a
              class="navbar-link"
              :class="{ 'nuxt-link-active nuxt-link-exact-active': $route.name === 'profile' }"
            ><i class="icon fad fa-wallet" /> <span>บัญชี</span></a>
          </router-link>
        </li>
      </ul>
    </nav>
    <!-- <div class="text-center neon">
      <div class="bottombar-mobile">
        <div class="container">
          <ul class="nav-menu">
            <li class="nav-item">
              <router-link :to="{ name: 'home' }">
                <a
                  class="nav-link"
                ><i class="fad fa-home" />
                  <span>หน้าหลัก</span>
                </a>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'deposit' }"
                class="nav-link"
              ><i class="fad fa-arrow-down" /><span>เติมเงิน</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'lottery' }"
                class="nav-link"
              >
                <i class="fad fa-edit" />

                <span>แทงหวย</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'withdraw' }"
                class="nav-link"
              >
                <i class="fad fa-arrow-up" />
                <span>ถอนเงิน</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'profile' }"
                class="nav-link"
              >
                <i class="fab fa-line" /><span>ติดต่อเรา</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
// import { BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    // BRow,
    // BCol,
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
  },
}
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');
.container {
  max-width: 768px;
}
span {
  font-size: 18px;
}
.bottombar-mobile {
  background: #FFFFFF;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  display: none;
  /* box-shadow: 0px -4px 20px rgba(218, 218, 218, 0.75); */
}
@media screen and (max-width: 2400px) {
  .bottombar-mobile {
    display: block;
  }
}
.bottombar-mobile .nav-menu {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-around;
}
.bottombar-mobile .nav-menu .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  list-style-type: none;
  text-align: center;
}
.bottombar-mobile .nav-menu .nav-item.middle-item span {
  font-size: 1rem;
  /* margin-top: 3px; */
  color: rgb(255, 255, 255);
  display: block;
}
span {
  font-size: 12px !important;
}
</style>
